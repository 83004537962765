.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

ul {
  list-style: none;
  z-index: 1;
}

a {
  text-decoration: none;
}

.menu-trigger img {
  position: absolute;
  top: 13px;
  right: 30px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  /* background-color: #fff; */
}
@media (max-width: 767px) {
  .menu-trigger img {
    right: 10px;
  }
}
.dropdown-menu {
  /* position: absolute;
    top: 100px;
    right: 20px;
    background-color: #fff;
    border-radius: var(--border-radius);
    padding: 10px 20px;
    width: 200px; */
  top: 100px;
  right: 20px;
  position: absolute;
  padding: 7px 0px;
  display: flex;
  justify-content: flex-start;
  /* flex-direction: column; */
  background-color: rgb(255, 255, 255);
  width: 220px;
  min-width: 300px;
  flex-shrink: 0;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 10px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  z-index: -1;
}

.dropdown-menu::before {
  content: "";
  position: absolute;
  top: -5px;
  right: 20px;
  height: 20px;
  width: 20px;
  background: var(--secondary-bg);
  transform: rotate(45deg);
}

.dropdown-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
}

.dropdown-menu.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: var(--speed) ease;
}

h3 {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 20px 0;
  font-weight: 500;
  font-size: 18px;
  color: var(--primary-text-color);
  line-height: 1.2rem;
}

h3 span {
  font-size: 14px;
  color: var(--secondary-text-color);
  font-weight: 400;
}

.dropdown-menu ul li {
  padding: 10px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.dropdown-menu ul li:hover a {
  color: rgb(212, 33, 9);
  cursor: pointer;
}

.dropdown-menu ul li:hover img {
  opacity: 1;
  cursor: pointer;
}

.dropdownItem {
  display: flex;
  margin: 10px auto;
}

.dropdownItem img {
  max-width: 20px;
  margin-right: 10px;
  opacity: 0.5;
  transition: var(--speed);
}

.dropdownItem a {
  max-width: 100px;
  margin-left: 10px;
  transition: var(--speed);
}
